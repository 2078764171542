import {
  Decoration,
  Loading,
  PrimaryButton,
  ProfilePicture,
} from "../../components";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { MainLogo } from "../Home";
import { useNavigate } from "react-router-dom";
import { FullUser, useUser } from "../../hooks";
import "./account.scss";

const Account = () => {
  const user = useUser<FullUser>(auth.currentUser?.uid ?? "", true);

  const nav = useNavigate();

  return (
    <>
      <MainLogo />
      <section className="part-page" id="account-section">
        {user.isLoading ? (
          <Loading />
        ) : (
          <>
            <Decoration
              top={5}
              left={0}
              fillColor="primary"
              opacity={0.15}
              blur={600}
              parallax={-0.6}
            >
              <svg
                viewBox="0 0 1000 1000"
                width={1000}
                height={1000}
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="500" cy="500" r="500" />
              </svg>
            </Decoration>
            <Decoration id="modify-profile" top={46}>
              <svg
                className="arrow"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
              >
                <path d="M29,431.52c35.42-11.85,85.45-31.86,140-66,81.67-51.11,137.5-88.02,137.5-165.02,0-63.2-36-129-118.5-131.98-56.5-2.04-103.9,37.57-109.5,87.98-8,72,34.75,128.96,81,140,88,21,167.4-45.97,202.5-73.98,63.92-51.01,94.81-113.02,109-148" />
                <polyline points="55 372 26.68 434.37 88 452" />
              </svg>
              <p>Modifie ton compte sur l'application Calshare ✏️</p>
            </Decoration>
            <div id="account">
              <ProfilePicture path={user.data?.profilePic} />
              <div id="infos">
                <h2 id="name">
                  {user.data?.accountType === "user"
                    ? user.data?.firstname + " " + user.data?.lastname
                    : user.data?.username}
                </h2>
                <h3 id="username">
                  {user.data?.accountType === "user"
                    ? user.data?.username
                    : user.data?.email}
                </h3>
                <p id="bio">{user.data?.bio}</p>
              </div>
            </div>
            <div id="actions">
              <h2>
                Salut{" "}
                <span className="highlighted" id="test">
                  {user.data?.accountType === "user"
                    ? user.data?.firstname
                    : user.data?.username}
                  ,
                </span>
              </h2>
              <h2>Quel bon vent t'amène ?</h2>
              <div id="buttons">
                <PrimaryButton icon="🏋️" link="/vote">
                  Voter pour un sport
                </PrimaryButton>
                <PrimaryButton icon="🖋️" link="/leave-a-print">
                  Laisser mon empreinte
                </PrimaryButton>
                <button
                  id="disconnect"
                  className="secondary"
                  onClick={() => {
                    signOut(auth);
                    nav("/");
                  }}
                >
                  Déconnexion
                </button>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default Account;
