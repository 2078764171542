import { ReactNode, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

interface Props {
  children: ReactNode;
  fillColor?: "primary" | "white" | "lightPrimary" | "gray";
  opacity?: number;
  blur?: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  parallax?: number;
  id?: string;
  className?: string;
  renderOnMobile?: boolean;
}

const Decoration = ({
  children,
  top,
  left,
  right,
  bottom,
  fillColor,
  opacity,
  blur,
  parallax,
  id,
  className,
  renderOnMobile,
}: Props) => {
  const [scrollOffset, setScrollOffset] = useState<number>();

  useEffect(() => {
    if (parallax) {
      window.addEventListener("scroll", (e) => setScrollOffset(window.scrollY));
    }
  }, [setScrollOffset, parallax]);

  return !isMobile || renderOnMobile ? (
    <div
      className={`decoration ${
        fillColor ? "decoration-color-" + fillColor : ""
      } ${className}`}
      style={{
        top: `calc(${top}vh - ${
          parallax && scrollOffset ? scrollOffset * parallax + "px" : "0px"
        })`,
        left: `${left}vw`,
        right: `${right}vw`,
        bottom: `calc(${bottom}vh - ${
          parallax && scrollOffset ? scrollOffset * parallax + "px" : "0px"
        })`,
        maxWidth: `calc(100vw - ${left ?? right ?? "0"}vw)`,
        opacity,
        filter: blur ? `blur(${blur}px)` : undefined,
      }}
      id={id}
    >
      {children}
    </div>
  ) : (
    <></>
  );
};

export default Decoration;
