import { useEffect, useState } from "react";
import {
  Decoration,
  Loading,
  PrimaryButton,
  TextInput,
} from "../../components";
import { MainLogo } from "../Home";
import "./register.scss";
import { auth, functions } from "../../firebase";
import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { EMAIL_REGEX, getPwdStrength } from "./Register";

const RegisterOrganisation = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState<boolean>();
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdValid, setPwdValid] = useState<boolean>();
  const [confirmPwd, setConfirmPwd] = useState("");
  const [pwdMatch, setPwdMatch] = useState<boolean>();
  const [cguChecked, setCguChecked] = useState(false);

  const [showPwd, setShowPwd] = useState(false);
  const [pwdStrength, setPwdStrength] = useState<{
    translation: string;
    id: string;
  }>();

  const nav = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setEmailValid(email !== "" ? EMAIL_REGEX.test(email) : undefined);
  }, [email]);

  useEffect(() => {
    setPwdValid(pwd !== "" ? true : undefined);
    setPwdStrength(getPwdStrength(pwd));
    setPwdMatch(confirmPwd !== "" ? confirmPwd === pwd : undefined);
  }, [pwd, confirmPwd]);

  async function submit() {
    setIsLoading(true);
    try {
      await httpsCallable(
        functions,
        "users-registerOrganisation"
      )({
        email,
        username,
        pwd,
      });
      await signInWithEmailAndPassword(auth, email, pwd);
      nav("/account");
    } catch (e) {
      setIsLoading(false);
      if (e instanceof FirebaseError) {
        if (e.message.includes("email-already-taken")) {
          setError("Cette adresse mail est déjà utilisée 🙁");
          return;
        }
        if (e.message.includes("username-already-taken")) {
          setError("Ce nom d'utilisateur n'est pas disponible 🙁");
          return;
        }
        if (e.message.includes("email-unauthorized")) {
          setError("Cette adresse n'a pas la permission d'ouvrir un compte 🙁");
          return;
        }
        if (e.code.includes("invalid-email")) {
          setError("Cette adresse mail est invalide 🙁");
          return;
        }
        if (e.code.includes("invalid-password")) {
          setError("Ce mot de passe ne convient pas 🙁");
          return;
        }
        if (e.code.includes("id-token-expired")) {
          setError("La session a expirée 🙁");
          return;
        }
        if (e.code.includes("id-token-expired")) {
          setError("Erreur interne 🙁");
          return;
        }
        setError(e.code.split("/")[1] + "🙁");
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <MainLogo />
      <Decoration
        top={10}
        right={5}
        fillColor="primary"
        opacity={0.15}
        blur={300}
        parallax={-0.8}
      >
        <svg
          viewBox="0 0 600 600"
          width={600}
          height={600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="300" cy="300" r="300" />
        </svg>
      </Decoration>
      <Decoration
        fillColor="primary"
        bottom={10}
        left={3}
        blur={200}
        opacity={0.1}
        parallax={-1.2}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(-20)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <Decoration
        top={200}
        right={200}
        fillColor="primary"
        opacity={0.15}
        blur={300}
        parallax={-0.8}
      >
        <svg
          viewBox="0 0 600 600"
          width={600}
          height={600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="300" cy="300" r="300" />
        </svg>
      </Decoration>
      <Decoration
        fillColor="primary"
        bottom={200}
        left={150}
        blur={200}
        opacity={0.1}
        parallax={-1.2}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(-20)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <section className="part-page" id="register-organisation-section">
        <div id="main-word">
          <h2>
            <span className="highlighted" id="test">
              Crééz votre compte,
            </span>
          </h2>
          <h2>
            Gagnez en <span className="highlighted">visibilité !</span>
          </h2>
        </div>
        <div id="forms-wrapper">
          <TextInput
            type="text"
            label="Adresse mail"
            placeholder="email@organisation.fr"
            value={email}
            valid={emailValid}
            onChange={(val) => setEmail(val)}
            helperShown={emailValid === false}
            helper={<p id="invalid-mail">Adresse mail invalide</p>}
          />
          <TextInput
            type="text"
            label="Nom de l'organisation"
            placeholder="Mon Organisation"
            value={username}
            onChange={(val) => setUsername(val)}
            helper={<></>}
          />
          <TextInput
            type="password"
            label="Mot de passe"
            placeholder="!unMDP-Bi1-R@buste"
            value={pwd}
            valid={pwdValid && (pwdMatch ?? true)}
            onChange={(val) => setPwd(val)}
            helperShown={pwd !== ""}
            helper={
              <div id="mdp-strength" className={pwdStrength?.id ?? ""}>
                <p>
                  Sécurité :{" "}
                  <span id="level">{pwdStrength?.translation ?? ""}</span>
                </p>
                <div id="bar-indicator"></div>
              </div>
            }
            showPwd={showPwd}
            setShowPwd={setShowPwd}
          />
          <TextInput
            type="password"
            label="Confirmation du mdp"
            value={confirmPwd}
            valid={pwdMatch}
            onChange={(val) => setConfirmPwd(val)}
            helperShown={pwdMatch === false}
            helper={
              <p id="pwd-no-match">Les mots de passe ne correspondent pas</p>
            }
            showPwd={showPwd}
            setShowPwd={setShowPwd}
          />
          <div id="cgu-accept">
            <input
              type="checkbox"
              checked={cguChecked}
              onChange={(e) => setCguChecked(e.currentTarget.checked)}
            />
            <p>
              En créant le compte, je reconnais avoir lu et accepté les{" "}
              <Link
                to={"https://swun.fr/assets/CGU_Calshare.pdf"}
                target="_blank"
                rel="norefferee"
              >
                Conditions Générales d'Utilisation
              </Link>{" "}
              ainsi que la{" "}
              <Link
                to={"https://swun.fr/wiki/calshare/privacy_policy/"}
                target="_blank"
                rel="norefferee"
              >
                Politique de Confidentialité
              </Link>
            </p>
          </div>
        </div>
        {error ? (
          <div id="error-msg">
            <p>Oups ! Huston, on a un problème :</p>
            <p id="error-code">{error}</p>
            <p>
              (Si le problème persiste, merci de nous contacter via{" "}
              <a href="mailto:contact@swun.fr">contact@swun.fr</a>)
            </p>
          </div>
        ) : (
          <></>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <PrimaryButton
            icon={"🚀"}
            enabled={
              emailValid === true &&
              pwdValid === true &&
              ["okay", "strong"].includes(pwdStrength?.id ?? "") &&
              pwdMatch === true &&
              cguChecked
            }
            onClick={submit}
          >
            C'est parti !!
          </PrimaryButton>
        )}
      </section>
    </>
  );
};

export default RegisterOrganisation;
export { EMAIL_REGEX };
