import { useEffect, useState } from "react";
import {
  Decoration,
  Loading,
  PrimaryButton,
  TextInput,
} from "../../components";
import { MainLogo } from "../Home";
import "./register.scss";
import { auth, functions } from "../../firebase";
import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PWD_STRENGTHS = {
  insufficient: {
    id: "insufficient",
    translation: "aucun effort...",
  },
  poor: {
    regExp: /^(?=.*[A-Z])(?=.*[a-z]).{1,}$/,
    id: "poor",
    translation: "... 🫣",
  },
  okay: {
    regExp:
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=(.*[!@#$%^&*()\-__+.])).{8,}$/,
    id: "okay",
    translation: "peut mieux faire",
  },
  strong: {
    regExp:
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=(.*[!@#$%^&*()\-__+.])).{12,}$/,
    id: "strong",
    translation: "parfait 👌",
  },
};

function getPwdStrength(pwd: string) {
  if (PWD_STRENGTHS.strong.regExp.test(pwd)) {
    return PWD_STRENGTHS.strong;
  }

  if (PWD_STRENGTHS.okay.regExp.test(pwd)) {
    return PWD_STRENGTHS.okay;
  }

  if (PWD_STRENGTHS.poor.regExp.test(pwd)) {
    return PWD_STRENGTHS.poor;
  }

  return PWD_STRENGTHS.insufficient;
}

interface Props {
  onAuthenticate?: () => void;
  setLoginOrRegister: (method: "register" | "login") => void;
}

const Register = ({ onAuthenticate, setLoginOrRegister }: Props) => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState<boolean>();
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdValid, setPwdValid] = useState<boolean>();
  const [confirmPwd, setConfirmPwd] = useState("");
  const [pwdMatch, setPwdMatch] = useState<boolean>();
  const [cguChecked, setCguChecked] = useState(false);

  const [showPwd, setShowPwd] = useState(false);
  const [pwdStrength, setPwdStrength] = useState<{
    translation: string;
    id: string;
  }>();

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setEmailValid(email !== "" ? EMAIL_REGEX.test(email) : undefined);
  }, [email]);

  useEffect(() => {
    setPwdValid(pwd !== "" ? true : undefined);
    setPwdStrength(getPwdStrength(pwd));
    setPwdMatch(confirmPwd !== "" ? confirmPwd === pwd : undefined);
  }, [pwd, confirmPwd]);

  async function submit() {
    setIsLoading(true);
    try {
      await httpsCallable(
        functions,
        "users-registerUser"
      )({
        email,
        username,
        firstname: firstName,
        lastname: lastName,
        pwd,
      });
      await signInWithEmailAndPassword(auth, email, pwd);
      onAuthenticate?.();
    } catch (e) {
      setIsLoading(false);
      if (e instanceof FirebaseError) {
        if (e.message.includes("email-already-taken")) {
          setError("Cette adresse mail est déjà utilisée 🙁");
          setPage(0);
          return;
        }
        if (e.message.includes("username-already-taken")) {
          setError("Ce nom d'utilisateur n'est pas disponible 🙁");
          return;
        }
        if (e.code.includes("invalid-email")) {
          setError("Cette adresse mail est invalide 🙁");
          setPage(0);
          return;
        }
        if (e.code.includes("invalid-password")) {
          setError("Ce mot de passe ne convient pas 🙁");
          setPage(0);
          return;
        }
        if (e.code.includes("id-token-expired")) {
          setError("La session a expirée 🙁");
          return;
        }
        if (e.code.includes("id-token-expired")) {
          setError("Erreur interne 🙁");
          return;
        }
        setError(e.code.split("/")[1] + "🙁");
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <MainLogo />
      <Decoration
        top={10}
        right={5}
        fillColor="primary"
        opacity={0.15}
        blur={300}
        parallax={-0.8}
      >
        <svg
          viewBox="0 0 600 600"
          width={600}
          height={600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="300" cy="300" r="300" />
        </svg>
      </Decoration>
      <Decoration
        fillColor="primary"
        bottom={10}
        left={3}
        blur={200}
        opacity={0.1}
        parallax={-1.2}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(-20)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <Decoration
        top={200}
        right={200}
        fillColor="primary"
        opacity={0.15}
        blur={300}
        parallax={-0.8}
      >
        <svg
          viewBox="0 0 600 600"
          width={600}
          height={600}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="300" cy="300" r="300" />
        </svg>
      </Decoration>
      <Decoration
        fillColor="primary"
        bottom={200}
        left={150}
        blur={200}
        opacity={0.1}
        parallax={-1.2}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(-20)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <section className="part-page" id="register-section">
        <div id="main-word">
          <h2>
            <span className="highlighted" id="test">
              Créé ton compte,
            </span>
          </h2>
          <h2>
            Rejoins la <span className="highlighted">waiting list</span>
          </h2>
        </div>
        <div id="forms-wrapper">
          <div
            id="register-form"
            className={page === 0 ? "active" : "inactive"}
          >
            <TextInput
              type="text"
              label="Adresse mail"
              placeholder="john.doe@exemple.fr"
              value={email}
              valid={emailValid}
              onChange={(val) => setEmail(val)}
              helperShown={emailValid === false}
              helper={<p id="invalid-mail">Adresse mail invalide</p>}
            />
            <TextInput
              type="password"
              label="Mot de passe"
              placeholder="!unMDP-Bi1-R@buste"
              value={pwd}
              valid={pwdValid && (pwdMatch ?? true)}
              onChange={(val) => setPwd(val)}
              helperShown={pwd !== ""}
              helper={
                <div id="mdp-strength" className={pwdStrength?.id ?? ""}>
                  <p>
                    Sécurité :{" "}
                    <span id="level">{pwdStrength?.translation ?? ""}</span>
                  </p>
                  <div id="bar-indicator"></div>
                </div>
              }
              showPwd={showPwd}
              setShowPwd={setShowPwd}
            />
            <TextInput
              type="password"
              label="Confirmation du mdp"
              value={confirmPwd}
              valid={pwdMatch}
              onChange={(val) => setConfirmPwd(val)}
              helperShown={pwdMatch === false}
              helper={
                <p id="pwd-no-match">Les mots de passe ne correspondent pas</p>
              }
              showPwd={showPwd}
              setShowPwd={setShowPwd}
            />
          </div>
          <div id="account-form" className={page === 1 ? "active" : "inactive"}>
            <TextInput
              type="text"
              label="Nom d'utilisateur"
              placeholder="john_doe"
              value={username}
              onChange={(val) => setUsername(val)}
            />
            <TextInput
              type="text"
              label="Prénom"
              placeholder="John"
              value={firstName}
              onChange={(val) => setFirstName(val)}
            />
            <TextInput
              type="text"
              label="Nom de famille"
              placeholder="Doe"
              value={lastName}
              onChange={(val) => setLastName(val)}
            />
            <div id="cgu-accept">
              <input
                type="checkbox"
                checked={cguChecked}
                onChange={(e) => setCguChecked(e.currentTarget.checked)}
              />
              <p>
                En créant mon compte, je reconnais avoir lu et accepté les{" "}
                <Link
                  to={"https://swun.fr/assets/CGU_Calshare.pdf"}
                  target="_blank"
                  rel="norefferee"
                >
                  Conditions Générales d'Utilisation
                </Link>{" "}
                ainsi que la{" "}
                <Link
                  to={"https://swun.fr/wiki/calshare/privacy_policy/"}
                  target="_blank"
                  rel="norefferee"
                >
                  Politique de Confidentialité
                </Link>
              </p>
            </div>
            <p id="back-arrow" onClick={() => setPage(0)}>
              ⬅️
            </p>
          </div>
        </div>
        {error ? (
          <div id="error-msg">
            <p>Oups ! Huston, on a un problème :</p>
            <p id="error-code">{error}</p>
            <p>
              (Si le problème persiste, merci de nous contacter via{" "}
              <a href="mailto:contact@swun.fr">contact@swun.fr</a>)
            </p>
          </div>
        ) : (
          <></>
        )}
        {isLoading ? (
          <Loading />
        ) : (
          <PrimaryButton
            icon={page === 0 ? "➡️" : "🚀"}
            enabled={
              page === 0
                ? emailValid === true &&
                  pwdValid === true &&
                  ["okay", "strong"].includes(pwdStrength?.id ?? "") &&
                  pwdMatch === true
                : !!username && !!firstName && !!lastName && cguChecked
            }
            onClick={() => {
              if (page === 0) {
                setPage(1);
              } else {
                submit();
              }
            }}
          >
            C'est parti !!
          </PrimaryButton>
        )}
        <p className="link" onClick={() => setLoginOrRegister("login")}>
          J'ai déjà un compte
        </p>
      </section>
    </>
  );
};

export default Register;
export { EMAIL_REGEX, PWD_STRENGTHS, getPwdStrength };
