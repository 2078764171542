import { ReactNode, useState } from "react";
import {
  AlreadyMember,
  AnimateOnScroll,
  DecoratedSection,
  Decoration,
  EnrollButton,
  Paragraph,
  PrimaryButton,
  WordsList,
} from "../../components";
import "./home.scss";
import "./sections.scss";
import { Link } from "react-router-dom";

const MainLogo = () => {
  return (
    <Link to={"/"} id="top-logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2000 100"
        fill="none"
      >
        <path d="M903.1,75.75H0s0-9.74,0-9.74h905.6c5.34-4.62,2.1-10.54-2.5-10.54h-16.47c-2.02,0-3.99-.4-5.84-1.18-1.79-.76-3.39-1.84-4.77-3.21s-2.46-2.98-3.21-4.77c-.78-1.85-1.18-3.82-1.18-5.84s.4-3.99,1.18-5.84c.76-1.79,1.84-3.39,3.21-4.77s2.98-2.46,4.77-3.21c1.85-.78,3.82-1.18,5.84-1.18h28.69l2.83,9.74h-34.02c-5.34,4.62-2.1,10.54,2.5,10.54h16.47c2.02,0,3.99.4,5.84,1.18,1.79.76,3.39,1.84,4.77,3.21,1.38,1.38,2.46,2.98,3.21,4.77.78,1.85,1.18,3.82,1.18,5.84s-.4,3.99-1.18,5.84c-.76,1.79-1.84,3.39-3.21,4.77-1.38,1.38-2.98,2.46-4.77,3.21-1.85.78-3.82,1.18-5.84,1.18Z" />
        <path d="M988.6,61.98c2.18,5.15,5.28,9.78,9.21,13.76h-35.16l-7.88-17.16-8.15,17.16-11.54-.04-13.87-50.29h11.56l9.63,34.9,6.65-14h11.67l6.14,13.37,9.44-34.23h8.86v19.41c0,5.93,1.16,11.69,3.46,17.11Z" />
        <path d="M1120.32,51.04v14.96h879.68v9.58h-891.24v-24.54c0-4.04-1.57-7.84-4.43-10.7-2.86-2.86-6.66-4.43-10.7-4.43s-7.84,1.57-10.7,4.43c-2.86,2.86-4.43,6.66-4.43,10.7v22.54c-3.19,0-6.08-1.29-8.17-3.39-1.17-1.17-2.09-2.59-2.67-4.17h0c.72-1.32,1.38-2.67,1.97-4.07,2.3-5.43,3.46-11.18,3.46-17.11v-2.73c0-5.22-1.08-10.34-3.13-15.05,2.11-2.32,5.16-3.77,8.54-3.77v5.75c1.48-1.02,3.07-1.89,4.74-2.6,3.29-1.39,6.79-2.1,10.39-2.1s7.1.71,10.39,2.1c3.18,1.34,6.03,3.27,8.48,5.72,2.45,2.45,4.37,5.3,5.72,8.48,1.39,3.29,2.1,6.79,2.1,10.39Z" />
        <path d="M1057.9,20.65c5.82,5.99,9.03,14.05,9.03,22.4v2.35c0,5.11-1,10.07-2.98,14.74-1.91,4.51-4.64,8.56-8.11,12.03-3.47,3.47-7.52,6.2-12.03,8.11-4.67,1.98-9.63,2.98-14.73,2.98v.02h-.02c-5.11,0-10.07-1-14.74-2.98-4.51-1.91-8.56-4.64-12.03-8.11-3.47-3.47-6.2-7.52-8.11-12.03-1.98-4.67-2.98-9.63-2.98-14.74V13.97h17.38v31.45c0,11.29,9.18,20.48,20.47,20.48v-.02h.02c11.29,0,20.48-9.19,20.48-20.48V14.07c2.95,1.74,5.65,3.85,8.05,6.27.1.1.2.21.31.31Z" />
        <text className="cls-1" transform="translate(1108 100)">
          PROJECT
        </text>
      </svg>
    </Link>
  );
};

const MainWords = () => {
  return (
    <section className="part-page">
      <div id="main-word">
        <h2>
          Suggère <span className="highlighted">tes idées,</span>
        </h2>
        <h2>
          <span className="highlighted">Participe</span> au développement,
        </h2>
        <h2>
          Rejoins la <span className="highlighted">waiting list !</span>
        </h2>
      </div>
    </section>
  );
};

const CalshareLogo = () => {
  const [animatePath, setAnimatePath] = useState(false);
  const [animateStart, setAnimateStart] = useState(false);
  const [animateDest, setAnimateDest] = useState(false);

  return (
    <svg
      id="Calshare_ios"
      data-name="Calshare ios"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 500 500"
    >
      <rect id="bg" width={500} height={500} rx={140} ry={140} />
      <AnimateOnScroll setAnimate={setAnimatePath} selector="#calshare-section">
        <path
          id="S_line_path"
          data-name="S line path"
          className={`stroked stroked-1 ${animatePath ? "drawPath" : ""}`}
          d="M315.2,121.69l-129.77-.17c-35.48,0-64.24,29.46-64.24,64.94s28.76,65.63,64.24,65.63l128.82-.06c35.48,0,64.24,30.15,64.24,65.63s-26.89,64.94-65.63,64.94h-155.01"
        />
      </AnimateOnScroll>
      <AnimateOnScroll setAnimate={setAnimateStart} selector="g#start">
        <g id="start" className={animateStart ? "enterGeneric" : ""}>
          <circle
            className="stroked stroked-2"
            cx="356.28"
            cy="121.52"
            r="27.46"
          />
          <circle className="filled" cx="356.28" cy="121.52" r="17.1" />
        </g>
      </AnimateOnScroll>
      <AnimateOnScroll setAnimate={setAnimateDest} selector="path#dest">
        <path
          id="dest"
          className={`filled ${animateDest ? "enterGeneric" : ""}`}
          d="M148.83,343.89c-3.65-3.73-8.76-6.1-14.55-6.1-13.58,0-23.37,13.02-19.59,26.06l15.14,39.03c1.57,4.06,7.32,4.06,8.9,0l15.14-39.03c2.17-7.48-.13-14.95-5.04-19.97ZM140.41,372.06c-12.7,5.22-24.73-6.81-19.51-19.51,1.35-3.28,3.97-5.9,7.25-7.25,12.7-5.22,24.73,6.81,19.51,19.51-1.35,3.28-3.97,5.9-7.25,7.25Z"
        />
      </AnimateOnScroll>
    </svg>
  );
};

const ProjectDescription = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children: ReactNode;
}) => {
  return (
    <>
      <h2>{title}</h2>
      <p className="subtitle">{subtitle}</p>
      {children}
    </>
  );
};

const Calshare = () => {
  return (
    <DecoratedSection
      className="part-page project-section"
      id="calshare-section"
      decoration={
        <>
          <img
            id="screen"
            src="assets/iPhone_illu.png"
            alt="Calshare screenshot"
            draggable={false}
          />
          <CalshareLogo />
          <a
            href="https://apps.apple.com/fr/app/calshare/id6451190580"
            target="_blank"
            rel="noreferrer"
          >
            <img
              id="app-store"
              src="assets/app-store-badge-white.png"
              alt="Disponible sur App Store"
              draggable={false}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=fr.swun.calshare&hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <img
              id="google-play"
              src="assets/google-play-badge.png"
              alt="Disponible sur Google Play"
              draggable={false}
            />
          </a>
        </>
      }
    >
      <ProjectDescription
        title="Calshare"
        subtitle="L'app pour tous vos trajets sportifs"
      >
        <p>
          Ne vous <em>prenez plus la tête</em> pour <em>organiser</em> vos
          trajets !
          <br />
          Utilisez Calshare et rendez-vous{" "}
          <em>directement sur les lieux de votre événement,</em> en compagnie
          d'autres personnes qui <em>partagent</em> votre <em>enthousiasme.</em>
        </p>
        <Link
          to="calshare/"
          className="fancy-link"
          target="_blank"
          rel="noreferrer"
        >
          En savoir plus
        </Link>
      </ProjectDescription>
    </DecoratedSection>
  );
};

const Home = () => {
  const [animateDiscovery, setAnimateDiscovery] = useState(false);

  return (
    <>
      <MainLogo />
      <MainWords />
      <Decoration
        top={-20}
        right={-10}
        fillColor="primary"
        opacity={0.08}
        blur={600}
        parallax={-0.6}
      >
        <svg
          viewBox="0 0 1000 1000"
          width={"80vw"}
          height={"80vw"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="500" cy="500" r="500" />
        </svg>
      </Decoration>
      <Decoration
        fillColor="primary"
        bottom={-60}
        left={10}
        blur={200}
        opacity={0.1}
        parallax={0.4}
      >
        <svg
          viewBox="0 0 400 1200"
          width={400}
          height={1200}
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(-20)"
        >
          <rect width={400} height={1200} x1={0} y1={0} />
        </svg>
      </Decoration>
      <Decoration
        bottom={-220}
        right={38}
        fillColor="primary"
        opacity={0.1}
        blur={600}
        parallax={-0.2}
      >
        <svg
          viewBox="0 0 1000 1000"
          width={1000}
          height={1000}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="500" cy="500" r="500" />
        </svg>
      </Decoration>
      <AlreadyMember />
      <EnrollButton />
      <section className="full-page" id="goals">
        <Paragraph highlighted="Le projet SWUN,">
          c'est l'ambition de deux pratiquants de Street Workout (a.k.a.
          Callisthénie) de rendre le sport plus plaisant, plus social et plus
          organisé.
        </Paragraph>
        <div id="swun-goals">
          <p id="sentence-start">SWUN existe car</p>
          <div>
            <p className="swun-goal">
              on ne connait pas toujours ses potentiels partenaires de séance
            </p>
            <p className="swun-goal">
              s'organiser pour se rendre à un événement sportif est souvent
              prise de tête
            </p>
            <p className="swun-goal">
              on n'est pas toujours au courant de tous les événements
            </p>
          </div>
        </div>
      </section>
      <section className="full-page" id="trusted-words">
        <h2>Ils soutiennent le projet :</h2>
        <WordsList />
        <PrimaryButton icon="🖋️" link="/leave-a-print">
          Ajouter mon empreinte
        </PrimaryButton>
      </section>
      <section className="full-page" id="calshare-section-wrapper">
        <h2 id="projects-title">
          Découvrez{" "}
          <span
            id="highlighted"
            className={animateDiscovery ? "animateDiscovery" : ""}
          >
            l'univers SWUN
          </span>
        </h2>
        <AnimateOnScroll
          setAnimate={setAnimateDiscovery}
          selector="#calshare-section"
          offset={0.5}
        >
          <Calshare />
        </AnimateOnScroll>
      </section>
      <EnrollButton />
    </>
  );
};

export default Home;
export { MainLogo };
